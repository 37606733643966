export const customerStatus = { DELETED: -1, ACTIVE: 1, INACTIVE: 0 };

const initialCustomers = {
  rowsPerPage: 10,

  search: '',

  currentData: {},

  isDeleting: false,
  isError: false,
  isLoading: false,
  isSubmitting: false,

  data: [],
  pagination: {
    total: 0,
    per_page: 10,
    current_page: 1,
  },
  filters: { status: customerStatus.ACTIVE }
};

export const actions = {
  DESTROY_CUSTOMER: 'CUSTOMERS_DESTROY_CUSTOMER',

  ADD_ONE: 'CUSTOMERS_ADD_ONE',
  GET_ALL: 'CUSTOMERS_GET_ALL',
  GET_ONE: 'CUSTOMERS_GET_ONE',

  SET_CURRENT_DATA: 'CUSTOMERS_SET_CURRENT_DATA',

  SET_IS_DELETING: 'CUSTOMERS_SET_IS_DELETING',
  SET_IS_ERROR: 'CUSTOMERS_SET_IS_ERROR',
  SET_IS_LOADING: 'CUSTOMERS_SET_IS_LOADING',
  SET_IS_SUBMITTING: 'CUSTOMERS_SET_IS_SUBMITTING',

  SET_ROWS_PER_PAGE: 'CUSTOMERS_SET_ROWS_PER_PAGE',
  SET_SEARCH: 'CUSTOMERS_SET_SEARCH',

  SET_FILTERS: 'CUSTOMERS_SET_FILTERS',
  UPDATE_ONE: 'CUSTOMERS_UPDATE_ONE',

  TOGGLE_ACTIVE: 'CUSTOMERS_TOGGLE_ACTIVE'
};

const destroyCustomer = (state = initialCustomers, action) => {
  const data = state.allData.filter(({ id }) => id !== action.data.id);
  const index = state.allData.findIndex(({ id }) => id === action.data.id);

  return {
    ...state,
    data: action.data.customers,
    allData: data,
    allDeleted: [...state.allDeleted, state.allData[index]]
  };
};

const toggleActive = (state, action) => {
  const newState = state;
  const { customer } = action.data;

  newState.currentData = customer;

  newState.data.find((item) =>
    item.id === customer.id ? Object.assign(item, { ...customer }) : item
  );

  return newState;
};

const reducer = (state = initialCustomers, action) => {
  switch (action.type) {
    case actions.DESTROY_CUSTOMER:
      return destroyCustomer(state, action);

    case actions.GET_ALL:
      return {
        ...state,
        data: [...action.data.customers],
        pagination: action.data.pagination
      };

    case actions.ADD_ONE:
      return { ...state, data: [...state.data, action.data] };

    case actions.GET_ONE:
      return { ...state, ...action.data };

    case actions.SET_CURRENT_DATA:
      return { ...state, ...action.data };

    case actions.SET_IS_DELETING:
      return { ...state, ...action.data };

    case actions.SET_IS_ERROR:
      return { ...state, ...action.data };

    case actions.SET_IS_LOADING:
      return { ...state, ...action.data };

    case actions.SET_IS_SUBMITTING:
      return { ...state, ...action.data };

    case actions.SET_ROWS_PER_PAGE:
      return { ...state, ...action.data };

    case actions.SET_SEARCH:
      return { ...state, ...action.data };

    case actions.SET_FILTERS:
      return { ...state, filters: { ...state.filters, ...action.data } };

    case actions.UPDATE_ONE:
      return {
        ...state,
        data: state.data.map((customer) => {
          if (customer.id === action.data.id)
            return { ...customer, ...action.data };

          return customer;
        })
      };

    case actions.TOGGLE_ACTIVE:
      return toggleActive(state, action);

    default:
      return state;
  }
};

export * from './actions';

export default reducer;
