/* eslint-disable no-console */
import api from '@services/_api';
import { actions } from '.';

const BASE_URL = 'api/v1/painel/clientes';

export const setIsDeleting = (isDeleting) => {
  return { type: actions.SET_IS_DELETING, data: { isDeleting } };
};

export const setIsError = (isError) => {
  return { type: actions.SET_IS_ERROR, data: { isError } };
};

export const setIsLoading = (isLoading) => {
  return { type: actions.SET_IS_LOADING, data: { isLoading } };
};

export const setCurrentData = (currentData) => (dispatch) => {
  dispatch({ type: actions.SET_CURRENT_DATA, data: { currentData } });
};

export const setRowsPerPage = (rowsPerPage) => (dispatch) => {
  dispatch({ type: actions.SET_ROWS_PER_PAGE, data: { rowsPerPage } });
};

export const setSearch = (search) => (dispatch) => {
  dispatch({ type: actions.SET_SEARCH, data: { search } });
};

export const setFilters = (data) => (dispatch) => {
  dispatch({ type: actions.SET_FILTERS, data });
};

export const getCustomers = (params = {}) => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const res = await api.get(BASE_URL, { params });

    if (res.success) {
      const { data: customers, meta: pagination } = res.data;

      dispatch({
        type: actions.GET_ALL,
        data: { isError: false, customers, pagination }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsLoading(false));
};

export const getCustomer = (id) => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await api.get(`${BASE_URL}/${id}`);
    if (res.success) {
      dispatch({
        type: actions.GET_ONE,
        data: { isError: false, currentData: res.data }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsLoading(false));
};

export const destroyCustomer = (customerId) => async (dispatch) => {
  dispatch(setIsDeleting(true));
  try {
    const res = await api.delete(`${BASE_URL}/${customerId}`);

    if (res.success) {
      dispatch({
        type: actions.DESTROY_CUSTOMER,
        data: { isError: false, id: customerId }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch(setIsError(true));
  }
  dispatch(setIsDeleting(false));
};

export const toggleActive = (id) => async (dispatch) => {
  try {
    const res = await api.put(`${BASE_URL}/${id}/toggle`);

    if (res.success) {
      dispatch({
        data: { customer: res.data },
        type: actions.TOGGLE_ACTIVE
      });
    }
  } catch (error) {
    console.log(error);
  }
};
