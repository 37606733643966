/* eslint-disable no-console */
import { toast } from 'react-toastify';

import api, { endpoints } from '@services/_api';

import { eventDispatcher } from '@hooks/use-event';

/**
 * @param {import('.').InsuranceState['currentData']} currentData
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const setCurrentData = (currentData) => (dispatch) => {
  dispatch({ type: 'setCurrentData', data: currentData });
};

/**
 *
 * @param {*} search
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const setFormData = (data) => (dispatch) => {
  dispatch({ type: 'setFormData', data });
};

/**
 *
 * @param {*} search
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const setStepper = (data) => (dispatch) => {
  dispatch({ type: 'setStepper', data });
};

/**
 *
 * @param {*} search
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const setModalPix = ({ isOpen = false, data = {} }) => (dispatch) => {
  dispatch({ type: 'setModalPix', data: { isOpen, data } });
};

/**
 *
 * @param {*} search
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const resetState = (data = []) => (dispatch) => {
  dispatch({ type: 'resetState', data });
};

/**
 *
 * @param {boolean} active
 * @param {string} message
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const setSending = (active = false, message = '') => (dispatch) => {
  dispatch({ type: 'setSending', data: { active, message } });
};

/**
 * @param {number} id
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const deleteInsurance = (id) => async (dispatch) => {
  dispatch({ type: 'deleteInsurance', data: { id } });
};

/**
 * @returns {(dispatch: import('.').InsuranceDispatcher) => Promise<void>}
 */
export const fetchInsurances = (params = {}) => async (dispatch) => {
  try {
    dispatch({ type: 'setLoading', data: true });

    const response = await api.get(endpoints.seguros, { params });

    const { data, meta: pagination } = response.data;

    dispatch({ type: 'fetchInsurances', data, pagination });
  } catch (error) {
    dispatch({ type: 'setLoading', data: false });
  }
};

/**
 *
 * @param {number} id
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const getInsurance = (id) => async (dispatch) => {
  try {
    dispatch({ type: 'setShowing', data: true });

    const res = await api.get(`${endpoints.seguros}/${id}`, {
      useOriginalResponse: true
    });

    dispatch({ type: 'getInsurance', data: res.data });
  } catch (error) {
    toast.error('Falha no carregamento dos dados');
    dispatch({ type: 'setShowing', data: false });
  }
};

/**
 * @param {*} data
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const storeInsurance = (data) => async (dispatch) => {
  try {
    dispatch(setSending(true, 'Criando assinatura'));

    const insurance = (await api.post(endpoints.seguros, data)).data;

    dispatch({
      type: 'addOne',
      data: {
        data: insurance,
        sending: { active: false, message: 'Assinatura criada' }
      }
    });

    eventDispatcher('close-dialog', { target: 'seguro-form' });

    const subscription = insurance?.subscription ?? null;

    if (data?.subscription?.is_pix && !!subscription) {
      dispatch(setModalPix({ isOpen: true, data: subscription }));
    }
  } catch (error) {
    dispatch(setSending(false, 'Falha ao criar assinatura'));
  } finally {
    dispatch(setSending());
  }
};

/**
 *
 * @param {*} id
 * @param {*} data
 * @returns {(dispatch: import('.').InsuranceDispatcher) => any}
 */
export const updateInsurance = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: 'setIsSubmitting', data: true });

    const res = await api.put(`${endpoints.seguros}/${id}`, data);

    dispatch({
      type: 'updateOne',
      data: { data: res.data, isSubmitting: false }
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: 'setIsSubmitting', data: false });
  }
};

/**
 *
 * @param {number} customerId
 */
export const updateCreditHistory = async (customerId) => {
  try {
    await api.put(`api/v1/painel/historico-credito/${customerId}`);
  } catch (error) {
    console.log(error);
  }
};
